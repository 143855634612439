<template>
    <div>
        <v-snackbar color="success" top v-model="snackbar">
            {{callbackResponse.message}}
            <v-btn @click="snackbar = false" dark text>Close</v-btn>
        </v-snackbar>
        <v-dialog max-width="500px" v-model="dialogEditCategory">
            <v-card>
                <v-toolbar class="darken-1" color="blue" dark>
                    <v-toolbar-title>
                        Edit Category
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-container>
                        <form>
                            <v-text-field
                                    :error-messages="categoryNameErrors"
                                    @blur="$v.categoryData.name.$touch()"
                                    @input="$v.categoryData.name.$touch()"
                                    label="Name"
                                    required
                                    v-model="categoryData.name"
                            ></v-text-field> 
                        </form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="updateCategory" color="blue darken-1" dark>Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog max-width="500px" v-model="dialogEditType">
            <v-card>
                <v-toolbar class="darken-1" color="blue" dark>
                    <v-toolbar-title>
                        Edit Type
                    </v-toolbar-title>
                </v-toolbar>

                <v-card-text>
                    <v-container>
                        <form>
                            <v-text-field
                                    :error-messages="typeNameErrors"
                                    @blur="$v.typeData.name.$touch()"
                                    @input="$v.typeData.name.$touch()"
                                    label="Name"
                                    required
                                    v-model="typeData.name"
                            ></v-text-field>
                        </form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="updateType" color="blue darken-1" dark>Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog max-width="500px" v-model="dialogDelete">
            <deleteListData :deleteFormData="deleteFormData" @formResponse="onResponseDelete"></deleteListData>
        </v-dialog>
        <v-row>
            <v-col>

                <!--                    <v-text-field-->
                <!--                            label="Search"-->
                <!--                            v-model="serverParamsRoom.search"-->
                <!--                    ></v-text-field>-->
                <!--                    <v-btn color="primary" dark @click="loadItemsType">-->
                <!--                        <i class="material-icons">search</i>Search-->
                <!--                    </v-btn>-->
                <v-expansion-panels hover>
                    <v-expansion-panel>
                        <v-expansion-panel-header>Enter house name</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-card>
                                <v-card-text>
                                    <v-text-field
                                            :error-messages="categoryNameErrors"
                                            @blur="$v.categoryData.name.$touch()"
                                            @input="$v.categoryData.name.$touch()"
                                            label="House Name"
                                            outlined
                                            required
                                            v-model="categoryData.name"
                                    ></v-text-field>
                                </v-card-text>
                                <v-card-actions align="center">
                                    <v-btn @click="createCategory"
                                           class="my-4 float-right"
                                           color="primary"
                                           dark>
                                        Add House
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-container fluid>

                    <vue-good-table
                            :columns="columnsRoom"
                            :isLoading.sync="isLoadingType"
                            :pagination-options="{ enabled: true,  perPageDropdown: [20, 50, 100,200]}"
                            :rows="categoryRows"
                            :totalRows="categoryTotalRecords"
                            @on-column-filter="onColumnFilterCategory"
                            @on-page-change="onPageChangeCategory"
                            @on-per-page-change="onPerPageChangeCategory"
                            @on-sort-change="onSortChangeCategory"
                            mode="remote"
                    >
                        <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'actions'">
                  <v-icon @click="editItemCategory(props.row)" class="mr-2" small>edit</v-icon>
                  <v-icon @click="deleteItemCategory(props.row)" small>delete</v-icon>
                </span>
                            <!--                            <span v-else-if="props.column.field == 'sno'">-->
                            <!--                                {{props.index +1}}-->
                            <!--                            </span>-->
                        </template>
                    </vue-good-table>
                </v-container>
            </v-col>

            <v-col>

                <v-expansion-panels hover>
                    <v-expansion-panel>
                        <v-expansion-panel-header><!--Type Form--> Rooms</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-card>

                                
                                <v-card-text>
                                    <v-row>
                                        <v-col >
                                            <v-select :items="house"
                                                      label="Select House" item-value="id"
    item-text="value" v-model="typeData.id"></v-select>
                                        </v-col>
                                        
                                    </v-row>
                                </v-card-text> 


                                <v-card-text>
   
                                <v-text-field :error-messages="typeNameErrors"
                                  @blur="$v.typeData.name.$touch()"
                                  @input="$v.typeData.name.$touch()"
                                  label="Add Room"
                                  outlined
                                  required
                                  v-model="typeData.name"></v-text-field>
   


                                </v-card-text>
                                <v-card-actions>
                                    <v-btn @click="createType" class="ma-4 float-right" color="primary" dark>Add
                                        Type
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>

                <v-container fluid>
                    <vue-good-table
                            :columns="columnsRoom"
                            :isLoading.sync="isLoadingCategory"
                            :pagination-options="{ enabled: true,  perPageDropdown: [20, 50, 100,200]}"
                            :rows="typeRows"
                            :totalRows="typeTotalRecords"
                            @on-column-filter="onColumnFilterType"
                            @on-page-change="onPageChangeType"
                            @on-per-page-change="onPerPageChangeType"
                            @on-sort-change="onSortChangeType"
                            mode="remote"
                    >
                        <template slot="table-row" slot-scope="props">

                            <span v-if="props.column.field == 'actions'">
                              <v-icon @click="editItemType(props.row)" class="mr-2" small>edit</v-icon>
                              <!--<v-icon @click="deleteItemType(props.row)" small>delete</v-icon>-->
                            </span>

                                                        <span v-else-if="props.column.field == 'sno'">
                                                            {{props.index +1}}
                                                        </span>

                        </template>
                    </vue-good-table>
                </v-container>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import axios from "axios";
    import {required} from "vuelidate/lib/validators";
    import deleteListData from "@/components/deleteModal";
    import {parseJwt} from "@/mixins/parseJwt";

    export default {
        name: "List",
        mixins: [parseJwt],
        watch: {
            dialog(val) {
                val || this.close();
            },
        },
        computed: {
            categoryNameErrors() {
                const errors = [];
                if (!this.$v.categoryData.name.$dirty) return errors;
                !this.$v.categoryData.name.required && errors.push("Name is required.");
                return errors;
            },
            typeNameErrors() {
                const errors = [];
                if (!this.$v.typeData.name.$dirty) return errors;
                !this.$v.typeData.name.required && errors.push("Name is required.");
                return errors;
            }
        },
        components: {
            deleteListData
        },
        data() {
            return {
                callbackResponse: {
                    timeout: 600
                },
                snackbar: false,
                snackbarType: false,
                snackbarError: false,
                snackbarTypeError: false,
                snackbarMessage: "",
                categoryData: {},
                typeData: {},
                success: false,
                dialogCreateRoom: false,
                dialogEditCategory: false,
                dialogEditType: false,
                dialogDelete: false,
                panel: [0],
                deleteFormData: {},
                popout: true,
                isLoadingCategory: false,
                isLoadingType: false,
                columnsRoom: [
                    {
                        label: "S No.",
                        field: "countIndex"
                    },
                    {
                        label: "Name",
                        field: "name"
                    },
                    
                    {
                        label: "Actions",
                        field: "actions",
                        sortable: false
                    }
                ],
                categoryRows: [],
                house: [],
                typeRows: [],
                typeTotalRecords: 0,
                categoryTotalRecords: 0,
                serverParamsRoom: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "id",
                            type: "asc"
                        }
                    ],
                    page: 1,
                    perPage: 20
                },
                serverParamsType: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "id",
                            type: "asc"
                        }
                    ],
                    page: 1,
                    perPage: 20
                },

                user: [],
                editedIndex: -1,
                editedDataRoom: {}
            };
        },

        mounted() {
            this.loadItemsCategory();
            this.loadItemsType();
            this.getHouse();
        },
        validations: {
            categoryData: {
                name: {required}
            },
            typeData: {
                name: {required}
            }
        },
        destroyed() {
            console.log(this); // There's practically nothing here!
        },
        methods: {
            // getCurrentIndex(index) {
            //     return ((this.currentPage - 1) * this.currentPerPage) + index + 1;
            // },
            createCategory() {
                this.$v.categoryData.$touch();
                if (this.$v.categoryData.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    this.submitStatus = "ERROR";
                    axios
                        .post("Hotel/InsertIntoHotelRoomCategoryAsync", this.categoryData)
                        .then((response) => {
                            if (response.data.success == true) {
                                this.categoryData = {};
                                this.snackbar = true;
                                this.snackbarError = false;
                                this.callbackResponse.message = "Data Added Successfully"
                                setTimeout(function () {
                                    this.snackbar = false;
                                }, 300);
                                this.$v.$reset();
                                this.loadItemsCategory();
                                this.getHouse();
                            } else {
                                this.snackbarError = true;
                                this.errorMessage = response.data.errors[0];
                                setTimeout(function () {
                                    this.snackbarError = false;
                                }, 300);
                            }
                        });
                }
            },
            //async formData() {
            //    const housetype = await axios.get("Hotel/HouseList");
            //    this.house = housetype.data;
            //},
            createType() {
                this.$v.typeData.$touch();
                if (this.$v.typeData.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    axios
                        .post("Hotel/InsertIntoHotelRoomTypeAsync", this.typeData)
                        .then((response) => {
                            if (response.data.success == true) {

                                this.typeData = {};
                                this.snackbar = true;
                                this.snackbarError = false;
                                this.callbackResponse.message = "Data Added Successfully"

                                this.$v.$reset();
                                setTimeout(() => {
                                    this.snackbarType = false;
                                }, 500);
                                this.loadItemsType();
                                this.onResponseRoom();
                            } else {
                                this.snackbarTypeError = true;
                                this.callbackResponse.message = response.data.errors[0];
                                setTimeout(() => {
                                    this.snackbarTypeError = false;
                                }, 300);
                            }
                        })
                }
            },


            getHouse() {
                axios.get("Hotel/HouseList").then(response => this.house = response.data)
            },
            updateCategory() {
                this.$v.categoryData.$touch();
                if (this.$v.categoryData.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    axios
                        .post("Hotel/UpdateHotelCategoryAsync", this.categoryData)
                        .then(() => {
                            this.categoryData = {};
                            this.dialogEditCategory = false;
                            this.snackbar = true;
                            this.snackbarError = false;
                            this.callbackResponse.message = "Data Updated Successfully"
                            this.$v.$reset();
                            this.onResponseRoom();

                            setTimeout(function () {
                                this.snackbar = false;
                            }, 300);
                            this.loadItemsCategory();
                        });
                }
            },
            updateType() {
                this.$v.typeData.$touch();
                if (this.$v.typeData.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    axios.post("Hotel/UpdateHotelTypeAsync", this.typeData).then(() => {
                        this.typeData = {};
                        this.$v.$reset();
                        this.snackbar = true;
                        this.snackbarError = false;
                        this.callbackResponse.message = "Data Updated Successfully"
                        setTimeout(function () {
                            this.snackbar = false;
                        }, 300);
                        this.loadItemsType();
                    });
                }
            },


            onResponseRoom(data) {
                if (data.status) {
                    this.dialogCreateRoom = false;
                    this.dialogEditRoom = false;
                    this.callbackResponse.message = data.message;
                    this.$emit("formResponseRoom", data);
                    this.loadItemsCategory();
                } else {
                    this.dialogDelete = false;
                }
            },
            onResponseDelete(data) {
                console.log("ListRoomDelete", data);
                if (data) {
                    this.$emit("formResponseRoomDelete", data);
                    this.dialogDelete = false;
                    this.loadItemsCategory();
                } else {
                    this.dialogDelete = false;
                }
            },

            editItemCategory(item) {
                this.editedIndex = this.categoryRows.indexOf(item);
                this.categoryData = Object.assign({}, item);
                this.dialogEditCategory = true;
            },
            editItemType(item) {
                this.editedIndex = this.typeRows.indexOf(item);
                this.typeData = Object.assign({}, item);
                this.dialogEditType = true;
            },

            deleteItemCategory(props) {
                console.log(props);
                this.dialogDelete = true;
                this.deleteFormData.url =
                    "Hotel/DeleteHotelRoomCategoryAsync/" + props.id;
            },
            deleteItemType(props) {
                console.log(props);
                this.dialogDelete = true;
                this.deleteFormData.url = "Hotel/DeleteHotelRoomTypeAsync/" + props.id;
            },

            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedData = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            },

            onPageChangeCategory(params) {
                this.updateParamsCategory({page: params.currentPage});
                this.loadItemsCategory();
            },

            onPerPageChangeCategory(params) {
                this.updateParamsCategory({perPage: params.currentPerPage});
                this.loadItemsCategory();
            },

            onSortChangeCategory(params) {
                this.updateParamsCategory({
                    sort: [
                        {
                            type: params[0].type,
                            field: params[0].field
                        }
                    ]
                });
                this.loadItemsCategory();
            },

            onColumnFilterCategory(params) {
                this.updateParamsCategory(params);
                this.loadItemsCategory();
            },

            updateParamsCategory(newProps) {
                this.serverParamsRoom = Object.assign({}, this.serverParamsRoom, newProps);
            },

            updateParamsType(newProps) {
                this.serverParamsType = Object.assign({}, this.serverParamsType, newProps);
            },

            onPageChangeType(params) {
                this.updateParamsType({page: params.currentPage});
                this.loadItemsType();
            },

            onPerPageChangeType(params) {
                this.updateParamsType({perPage: params.currentPerPage});
                this.loadItemsType();
            },

            onSortChangeType(params) {
                this.updateParamsType({
                    sort: [
                        {
                            type: params[0].type,
                            field: params[0].field
                        }
                    ]
                });
                this.loadItemsType();
            },

            onColumnFilterType(params) {
                this.updateParamsType(params);
                this.loadItemsType();
            },

            async filterData() {
            },
            // load items is what brings back the rows from server

            loadItemsType() {
                let param = {
                    PageSize: this.serverParamsType.perPage,
                    PageNo: this.serverParamsType.page,
                    OrderType: this.serverParamsType.sort[0].type,
                    OrderBy: this.serverParamsType.sort[0].field,
                    name: this.serverParamsRoom.search
                };
                axios.post("Hotel/GetHotelRoomTypeList", param).then(response => {
                    this.typeRows = response.data.data;
                    this.typeTotalRecords = response.data.totalCount;
                });
            },
            loadItemsCategory() {

                let param = {
                    PageSize: this.serverParamsRoom.perPage,
                    PageNo: this.serverParamsRoom.page,
                    OrderType: this.serverParamsRoom.sort[0].type,
                    OrderBy: this.serverParamsRoom.sort[0].field
                };

                axios.post("Hotel/GetHotelRoomCategoryList", param).then(response => {
                    this.categoryRows = response.data.data;
                    this.categoryTotalRecords = response.data.totalCount;
                });

                return;
            }
        }
    };
</script>

<style lang="scss" scoped>
    .v-item-group {
        background: #d2e3ff73;
    }

    .v-expansion-panel-header {
        background: #d2e3ff73;
    }

    button {
        z-index: 1;
    }

    .form-category-setting {
        border: 1px solid;

        .form-category-setting-header {
            background-color: #1976d2;
            height: 2em;

            span {
                size: 1.5em;
                font-weight: 500;
                color: #ffffff;
            }
        }
    }
</style>