<template>
    <v-content>
        <v-breadcrumbs :items="items" large></v-breadcrumbs>

        <v-snackbar v-model="snackbar" color="success" :timeout="callbackResponse.timeout" top>
            {{callbackResponse.message}}
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-container flat>
            <CategoryList @formResponseRoom="onResponseRoom" @formResponseRoomDelete="onResponseDelete"/>
        </v-container>
    </v-content>
</template>

<script>
    import CategoryList from "./ListCategory";
    import {parseJwt} from "@/mixins/parseJwt";

    export default {
        name: "List",
        mixins: [parseJwt],
        watch: {
            dialog(val) {
                val || this.close();
            }, 
            success: {
                handler: function (val, oldVal) {
                    console.log(val, oldVal);
                    this.loadItems();
                },
                deep: true
            }
        },
        components: {
            CategoryList
        },
        data() {
            return {
                callbackResponse: {
                    timeout: 6000
                },
                snackbar: false,
                success: false,
                dialogCreateFare: false,
                dialogCreateRoom: false,
                dialogEditRoom: false,
                dialogEditFare: false,
                dialogDelete: false,
                panel: [0],
                deleteFormData: {},
                items: [
                    {
                        text: "Settings",
                        to: "/HomeStaySettings",
                        disabled: false,
                        exact: true
                    },
                    {
                        text: "Room Setting",
                        disabled: true
                    }
                ],
                totalRecords: 0
            };
        },

        mounted() {
        },
        destroyed() {
            console.log(this); // There's practically nothing here!
        },
        methods: {
            onResponseFare(data) {
                console.log(data);
                if (data.status) {
                    this.dialogCreateFare = false;
                    this.dialogEditFare = false;
                    this.callbackResponse.message = data.message;
                    this.snackbar = true;
                    this.loadItemsFare();
                }

            },
            onResponseRoom(data) {
                if (data.status) {
                    this.dialogCreateRoom = false;
                    this.dialogEditRoom = false;

                    this.callbackResponse.message = data.message;
                    this.snackbar = true;
                    this.loadItemsRoom();
                } else {
                    this.dialogDelete = false;
                }
            },
            onResponseDelete(data) {
                if (data) {
                    this.dialogDelete = false;
                    this.snackbar = true;
                    this.callbackResponse.message = "Data Deleted Successfully.";
                    this.loadItemsRoom();
                    this.loadItemsFare();
                } else {
                    this.dialogDelete = false;
                }
            },
            editItemRoom(props) {
                this.editedDataRoom = props;
                this.dialogEditRoom = true;
                // this.dialogEditFare = true;
            },
            editItemFare(props) {
                this.editedDataFare = props;
                this.dialogEditFare = true;
                // this.dialogEditFare = true;
            },

            deleteItemRoom(props) {
                console.log(props);
                this.dialogDelete = true;
                this.deleteFormData.url = "Hotel/DeleteHotelRoomAsync/" + props.roomID;
                this.loadItemsRoom();
            },
            deleteItemFare(props) {
                this.dialogDelete = true;
                this.deleteFormData.url =
                    "Hotel/DeleteHotelRoomFareAsync/" + props.fareID;
            },

            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedData = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            }
        }
    };
</script>

<style lang="scss" scoped>
    .v-item-group {
        background: #d2e3ff73;
    }

    .v-expansion-panel-header {
        background: #d2e3ff73;
    }

    button {
        z-index: 1;
    }
</style>